<template>
  <section class="flex flex-col gap-40 mx-auto">
    <div class="grid grid-cols-[1.2fr_1.4fr] md:grid-cols-1 gap-24">
      <div class="flex flex-col gap-24">
        <h2 class="text-title-1 text-neutral-100">
          Seguros Massificados e Corporativos
        </h2>
        <p class="text-pararaphy-1 text-neutral-70">
          <span class="font-semibold text-neutral-100"
            >Seguros Massificados e Corporativos</span
          >
          oferecem proteção abrangente para indivíduos, empresas e suas
          operações. Esses seguros são desenhados para atender às necessidades
          específicas de cada cliente, desde a cobertura de bens e
          responsabilidades até a proteção de veículos e imóveis. Com soluções
          personalizadas, esses seguros garantem tranquilidade e segurança para
          negócios de todos os tamanhos, ajudando a mitigar riscos e promover a
          continuidade das atividades empresariais.
        </p>

        <div class="flex gap-16 mt-24 md:justify-center">
          <OButton
            primary
            class=""
            size="md"
            @click="emits('click:openModalCreateIndicacao')"
            >Faça sua indicação à mesa</OButton
          >
          <OButton
            secondary
            size="md"
            icon="svguse:#icon_download"
            @click="
              emits('click:download', data.documento_corporate_massificados)
            "
            >Lâminas de Materiais</OButton
          >
        </div>
      </div>
      <CardImageRight
        :image="newUrlImg('images/seguros_massificados.webp')"
        icon-name="svguse:#icon_detail_massificados"
      />
    </div>
    <div>
      <swiper :space-between="20" slides-per-view="auto">
        <swiper-slide
          v-for="(modalidade, index) in modalidades"
          :key="index"
          class="max-w-[24.5rem] w-[24.5rem] min-w-[24.5rem] p-24"
        >
          <div class="flex flex-col gap-4">
            <h2 class="text-headline-1 text-neutral-100">
              {{ modalidade.title }}
            </h2>
            <span class="text-pararaphy-1 text-neutral-70">
              {{ modalidade.text }}
            </span>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script setup>
import { inject } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import CardImageRight from './CardImageRight.vue'
import GLOBAL from '../../../utils/GLOBAL'
import OButton from '../Button/OButton.vue'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
const {data} = inject('context')
const { newUrlImg } = GLOBAL
const emits = defineEmits(['click:openModalCreateIndicacao', 'click:download'])

const modalidades = [
  {
    title: 'Seguro Patrimonial',
    text: 'Proteja os ativos tangíveis da sua empresa, como imóveis, máquinas e equipamentos, contra danos e perdas inesperadas.',
  },
  {
    title: 'Seguro de Frota',
    text: 'Mantenha a frota da sua empresa protegida contra acidentes, roubos e danos, garantindo a continuidade das operações logísticas.',
  },
  {
    title: 'Seguro de Responsabilidade Civil',
    text: 'Ofereça proteção contra reclamações de terceiros por danos materiais ou pessoais causados pela empresa, assegurando a segurança jurídica.',
  },
  {
    title: 'Seguro Garantia',
    text: 'Assegure o cumprimento de contratos e obrigações legais, garantindo que a empresa cumpra seus compromissos ou que o contratante receba a indenização devida em caso de inadimplência.',
  },
  {
    title: 'Seguro de Responsabilidade Civil Profissional (RCP)',
    text: 'Proteja profissionais e empresas contra reclamações por falhas ou omissões na prestação de serviços, garantindo segurança no exercício das atividades.',
  },
  {
    title: 'Seguro Auto',
    text: 'Garanta a proteção do seu veículo particular ou de executivos contra acidentes, roubos e danos, assegurando a mobilidade com segurança.',
  },
  {
    title: 'Seguro Residencial',
    text: 'Proteja as residências dos seus colaboradores e executivos contra incêndios, furtos e danos, oferecendo segurança para suas famílias.',
  },
  {
    title: 'Seguro Viagem',
    text: 'Garanta assistência e cobertura em viagens corporativas, protegendo seus funcionários contra imprevistos no exterior ou em viagens nacionais.',
  },
]
</script>

<style lang="scss" scoped></style>
