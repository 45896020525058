<template>
  <section class="flex flex-col gap-40 mx-auto">
    <div class="grid grid-cols-[1.2fr_1.4fr] md:grid-cols-1 gap-24">
      <div class="flex flex-col gap-24">
        <h2 class="text-title-1 text-neutral-100">Financiamento Imobiliário</h2>
        <p class="text-pararaphy-1 text-neutral-70">
          <span class="font-semibold text-neutral-100"
            >Financiamento Imobiliário</span
          >
          é a chave para realizar o sonho da casa própria, permitindo que você
          adquira seu imóvel de forma planejada e acessível. Com diversas
          modalidades de financiamento, é possível encontrar a opção que melhor
          se adapta ao seu perfil e necessidades, garantindo condições justas e
          prazos adequados. A conquista do imóvel desejado pode ser mais próxima
          do que você imagina, com um financiamento bem estruturado.
        </p>

        <div class="flex gap-16 mt-24 md:justify-center">
          <OButton
            primary
            size="md"
            @click="emits('click:openModalCreateIndicacao')"
            >Faça sua indicação à mesa</OButton
          >
          <OButton
            secondary
            size="md"
            icon="svguse:#icon_download"
            @click="emits('click:download', data.documento_financiamento)"
            >Lâminas de Materiais</OButton
          >
        </div>
      </div>
      <CardImageRight
        :image="newUrlImg('images/imobiliario.webp')"
        icon-name="svguse:#icon_detail_imobiliario"
      />
    </div>
    <div>
      <swiper :space-between="20" slides-per-view="auto">
        <swiper-slide
          v-for="(modalidade, index) in modalidades"
          :key="index"
          class="max-w-[24.5rem] w-[24.5rem] min-w-[24.5rem] p-24"
        >
          <div class="flex flex-col gap-4">
            <h2 class="text-headline-1 text-neutral-100">
              {{ modalidade.title }}
            </h2>
            <span class="text-pararaphy-1 text-neutral-70">
              {{ modalidade.text }}
            </span>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import CardImageRight from './CardImageRight.vue'
import GLOBAL from '../../../utils/GLOBAL'
import OButton from '../Button/OButton.vue'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { inject } from 'vue'

const { newUrlImg } = GLOBAL
const { data } = inject('context')

const emits = defineEmits(['click:openModalCreateIndicacao', 'click:download'])
const modalidades = [
  {
    title: 'Financiamento com Taxa Fixa',
    text: 'Conheça a segurança de parcelas fixas durante todo o período do financiamento, sem surpresas ao longo do contrato.',
  },
  {
    title: 'Financiamento com Taxa Variável',
    text: 'Aproveite as condições de mercado com taxas que podem reduzir ao longo do tempo, ideal para quem busca flexibilidade financeira.',
  },
  {
    title: 'Financiamento pelo Sistema de Amortização Constante (SAC)',
    text: 'Beneficie-se de parcelas decrescentes, onde o valor da prestação diminui com o tempo, aliviando o orçamento familiar ao longo dos anos.',
  },
  {
    title: 'Financiamento pelo Sistema de Amortização Crescente (PRICE)',
    text: 'Opte por parcelas iniciais menores que aumentam ao longo do tempo, permitindo uma maior adaptação financeira no início do contrato.',
  },
  {
    title: 'Uso do FGTS no Financiamento',
    text: 'Utilize o saldo do Fundo de Garantia do Tempo de Serviço para dar entrada ou amortizar o saldo devedor, facilitando a conquista do imóvel.',
  },
]
</script>

<style lang="scss" scoped></style>
