<template>
  <section class="flex flex-col gap-40 mx-auto">
    <div class="flex flex-col gap-4">
      <label for="" class="text-caps-1 text-branding-light">Somos Uma</label>
      <h2 class="text-neutral-100 text-title-2">
        Plataforma de planejamento financeiro
      </h2>
    </div>
    <div class="grid grid-cols-[1.4fr_1.2fr] md:grid-cols-1 gap-24">
      <CardImageLeft
        :image="newUrlImg('images/image.webp')"
        icon-name="svguse:#icon_detalhe_foto"
      />

      <div class="flex flex-col gap-24">
        <h2 class="text-title-1 text-neutral-100">Seguro de Vida</h2>
        <p class="text-pararaphy-1 text-neutral-70">
          <span class="font-semibold text-neutral-100">Seguro de Vida </span>é
          uma proteção essencial que vai além de garantir tranquilidade
          financeira para os seus entes queridos. Ele assegura que, em momentos
          inesperados, seus objetivos e planos não sejam comprometidos. Com
          opções personalizáveis, o seguro de vida oferece coberturas que se
          adaptam às suas necessidades e garantem um futuro mais seguro para
          você e sua família.
        </p>

        <div class="flex gap-16 mt-24 md:justify-center">
          <OButton
            primary
            size="md"
            class=""
            @click="emits('click:openModalCreateIndicacao')"
            >Faça sua indicação à mesa</OButton
          >
          <OButton
            secondary
            size="md"
            icon="svguse:#icon_download"
            @click="emits('click:download', data.documento_seguro_vida)"
            >Lâminas de Materiais</OButton
          >
        </div>
      </div>
    </div>
    <div>
      <swiper :space-between="20" slides-per-view="auto">
        <swiper-slide
          v-for="(modalidade, index) in modalidades"
          :key="index"
          class="max-w-[24.5rem] w-[24.5rem] min-w-[24.5rem] p-24"
        >
          <div class="flex flex-col gap-4">
            <h2 class="text-headline-1 text-neutral-100">
              {{ modalidade.title }}
            </h2>
            <span class="text-pararaphy-1 text-neutral-70">
              {{ modalidade.text }}
            </span>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import CardImageLeft from './CardImageLeft.vue'
import GLOBAL from '../../../utils/GLOBAL'
import OButton from '../Button/OButton.vue'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { inject } from 'vue'

const { newUrlImg } = GLOBAL
const { data } = inject('context')
const emits = defineEmits(['click:openModalCreateIndicacao', 'click:download'])

const modalidades = [
  {
    title: 'Cobertura Whole Life/Vida Inteira',
    text: 'Seguro de vida com cobertura vitalícia e resgate do valor acumulado. Ideal para planejamento sucessório, oferece proteção contínua, garantindo segurança financeira e tranquilidade para sua família ao longo de toda a vida.',
  },
  {
    title: 'Cobertura por Invalidez Permanente Total ou Parcial por Acidente',
    text: 'Garantia de indenização em casos de perda de capacidade física parcial ou total, causada por acidente, oferecendo segurança financeira para adaptação à nova realidade.',
  },
  {
    title: 'Cobertura por Doenças Graves',
    text: 'Proteção financeira ao ser diagnosticado com doenças graves específicas, permitindo o foco na recuperação sem preocupações financeiras.',
  },
  {
    title: 'Cobertura por Morte Acidental',
    text: 'Indenização adicional em caso de morte acidental, proporcionando suporte financeiro extra para sua família.',
  },
  {
    title: 'Cobertura por Incapacidade Temporária',
    text: 'Auxílio financeiro em caso de afastamento temporário do trabalho por motivos de saúde, garantindo a manutenção do padrão de vida.',
  },
  {
    title:
      'Cobertura por Despesas Médicas, Hospitalares e Odontológicas (DMHO)',
    text: 'Reembolso de despesas médicas, hospitalares e odontológicas decorrentes de acidentes, garantindo acesso a cuidados de qualidade sem comprometer o orçamento familiar.',
  },
  {
    title: 'Diária por Incapacidade Temporária (DIT)',
    text: 'Receba uma renda diária em caso de afastamento temporário devido a doenças ou acidentes, garantindo a continuidade do seu sustento enquanto você se recupera. Essa cobertura é ideal para profissionais autônomos e liberais, que dependem diretamente de sua capacidade de trabalho para manter sua renda.',
  },
]
</script>

<style lang="scss" scoped></style>
