<template>
  <ModalSide
    v-if="state.open"
    card-class="!w-[600px] md:!w-full"
    :state="state"
    :open="state.open"
    :close="close"
  >
    <template #header>
      <header class="bg-primary-pure px-32">
        <div class="flex items-center w-full shrink-0">
          <div class="mr-24 flex flex-col py-24 gap-4">
            <q-chip
              color="secondary"
              class="max-w-min !bg-white/10 text-headline-4"
              text-color="white"
            >
              Indicação
            </q-chip>
            <TextIcon
              class="text-white"
              text="CRM"
              icon-class="hidden"
            />
          </div>

          <q-space></q-space>

          <OButton
            class="!p-0 place-items-center grid cursor-pointer rounded-full !w-40 !h-40"
            @click.stop.prevent="close"
          >
            <q-icon class="text-white" size="0.5rem" name="close"></q-icon>
          </OButton>
        </div>
      </header>
    </template>

    <template #content>
      <q-form
        ref="formRef"
        :no-error-focus="false"
        class="flex flex-col flex-1 flex-nowrap h-full p-24 px-32 gap-16"
      >
        <q-card class="p-16">
          <TextIcon
            class="mb-16 text-neutral-100 !text-caps-2"
            text="Dados do Assessor"
            icon="person"
            icon-class="!text-[1.5rem]"
          />

          <div class="flex gap-16 ">
            <div class="flex flex-col flex-1 " >
              <OLabel text="Código do Assessor" :required="true" />
              <OSelect 
                v-model="model.codigo_assessor"
                autofocus
                :rules="[(val) => val?.length || 'Campo Obrigatório']"
                size="md"
                use-input
                :options="options.codigo_assessor"
                option-label="label"
                option-value="value"
                map-options
                emit-value
                behavior="menu"
              />
            </div>
          </div>
        </q-card>

        <q-card class="p-16">
          <TextIcon
            class="mb-16 text-neutral-60 text-caps-2 !text-[0.875rem] !font-medium"
            text="DADOS DO CLIENTE"
            icon="person_add"
            icon-class="!text-[1.5rem]"
          />

          <fieldset class="gap-16 grid grid-cols-2 sm:grid-cols-1">
            <div class="flex flex-col flex-1 flex-nowrap">
              <OLabel text="O cliente tem Código XP?" :required="true" />

              <div class="flex items-center gap-8 py-8">
                <ORadio
                  v-model="model.botao_XP"
                  size="md"
                  label="Sim"
                  val="True"
                  name="botao_XP"
                />
                <ORadio
                  v-model="model.botao_XP"
                  size="md"
                  label="Não"
                  val="False"
                  name="botao_XP"
                  class="ml-24"
                />
              </div>
            </div>

            <div class="flex flex-col flex-1 flex-nowrap">
              <OLabel text="Código XP" :required="model.botao_XP === 'True'" />
              <OInput
                v-model="model.codigo_XP"
                :rules="[
                  (val) =>
                    (model.botao_XP === 'True' && !!val) || 'Campo Obrigatório',
                ]"
                :disable="model.botao_XP !== 'True'"
                size="md"
                name="botao_XP"
                :reactive-rules="true"
              />
            </div>
          </fieldset>

          <fieldset class="gap-16 mt-16 grid grid-cols-2 sm:grid-cols-1">
            <div class="flex flex-col flex-1 flex-nowrap">
              <OLabel text="Nome do Cliente" :required="true" />
              <OInput
                v-model="model.nome_cliente"
                size="md"
                :rules="[(val) => !!val || 'Campo Obrigatório']"
              />
            </div>

            <div class="flex flex-col flex-1 flex-nowrap">
              <OLabel text="Telefone do Cliente" />
              <OInput
                v-model="model.telefone"
                size="md"
                placeholder="(00) 00000-0000"
              />
            </div>
          </fieldset>

          <div class="flex flex-col flex-1 flex-nowrap mt-16">
            <OLabel text="Email do Cliente" />
            <OInput
              v-model="model.email"
              size="md"
              placeholder="seuemail@email.com"
            />
          </div>
          <fieldset></fieldset>
        </q-card>

        <q-card class="p-16">
          <TextIcon
            class="mb-16 text-neutral-60 text-caps-2 !text-[0.875rem] !font-medium"
            text="DADOS GERAIS"
            icon="work"
            icon-class="!text-[1.5rem]"
          />

          <fieldset class="grid grid-cols-2 md:grid-cols-1 gap-16 mt-16">
            <div class="flex flex-col flex-1 flex-nowrap">
              <OLabel text="Tipo de produto" :required="true" />

              <OSelect
                v-model="model.tipo_de_produto"
                :rules="[(val) => val?.length || 'Campo Obrigatório']"
                size="md"
                :options="options.tipo_de_produto"
                option-label="label"
                option-value="value"
                map-options
                emit-value
                behavior="menu"
               
              />
            </div>

            <div class="flex flex-col flex-1 flex-nowrap">
              <OLabel text="Produto" :required="true" />
              <OSelect
                v-model="model.produto"
                :rules="[(val) => val?.length || 'Campo Obrigatório']"
                size="md"
                use-input
                :options="options.produto"
                option-label="label"
                option-value="value"
                map-options
                emit-value
                :clearable="true"
                behavior="menu"
               
              />
            </div>
          </fieldset>

          <fieldset class="flex gap-16 mt-16">
            <div class="flex flex-col flex-1 flex-nowrap">
              <OLabel
                text="Você gostaria de incluir algum outro comentário ou observação?"
                :required="true"
              />
              <!--  :rules="[(val) => (!prodObrigatorio || (prodObrigatorio && val?.length)) || 'Campo Obrigatório']" -->
              <OInput
                v-model="model.observacoes"
                name="observacoes"
                :autogrow="true"
                :rules="[(val) => val?.length || 'Campo Obrigatório']"
                class="!pt-8 border border-neutral-20 dark:border-none"
              />
            </div>
          </fieldset>

          <fieldset class="flex gap-16 mt-16">
            <div class="flex flex-col flex-1 flex-nowrap">
              <OLabel text="Anexo" />

              <FilePicker
                :file="file"
                @update:model-value="(v) => (file = v)"
              />

              <p class="text-neutral-60 text-[10px] mt-2">
                Enviar um único arquivo compactado com os documentos
              </p>
            </div>
          </fieldset>
        </q-card>

        <div class="pb-24 flex gap-16">
          <OButton
            type="button"
            size="lg"
            class="flex-1 mt-24"
            secondary
            :loading="loading.modal"
            @click="close"
          >
            <p>Cancelar</p>
          </OButton>
          <OButton
            type="submit"
            size="lg"
            class="flex-1 mt-24"
            primary
            :loading="loading.modal"
            @click.prevent="onSubmit"
          >
            <p>Indicar</p>
          </OButton>
        </div>
      </q-form>
    </template>
  </ModalSide>
</template>

<script setup>
import { assessorService } from '../../services/assessor.service'
import { computed, inject, onMounted, provide, ref, unref, watch } from 'vue'
import { mesaSeguroService } from '../../services/mesa_seguro.service'
import { NotifyError, NotifySucess } from 'js/vue/boot/Notify'
import Emitter from '../../boot/Emitter'
import FilePicker from 'components/File/FilePicker.vue'
import ModalSide from './ModalSide.vue'
import OButton from '../Button/OButton.vue'
import OInput from 'components/Input/OInput.vue'
import OLabel from 'components/Label/OLabel.vue'
import ORadio from 'components/Radio/ORadio.vue'
import OSelect from 'components/Select/OSelect.vue'
import TextIcon from 'components/Text/TextIcon.vue'
import useKanban from 'composables/useKanban'
import useUrlParams from 'composables/useUrlParams'

const { modalKanbanIndicacaoCreate } = inject('modal')
const { getAssessores } = assessorService()
const { postIndicacaoMesaSeguro } = mesaSeguroService()
const { state, open, close, setState } = modalKanbanIndicacaoCreate

const { data, urls } = inject('context')

const { getSearchParam } = useUrlParams()
const urlFilters = computed(() => {
  const produto = getSearchParam('produto')
  const tipo = getSearchParam('tipo')

  return {
    produto,
    tipo,
  }
})

const modelDefault = {
  botao_XP: 'False',
  codigo_XP: '',
  nome_cliente: '',
  cliente_cambio_pf: null,
  cliente_cambio_pj: null,
  patrimonio_cliente: '',
  telefone: '',
  estrategia: '',
  tipo_de_produto: '',
  produto: '',
  conhecimento_indicacao: 'Não',
  abordagem: '',
  nome_empresa: '',
  relacionamento_cliente: '',
  setor_atuação: '',
  faturamento_anual: '',
  site: '',
  resumo: '',
  aceitar_termos: false,
  codigo_assessor: '',
  tipo_pessoa: '',
  celular_acessor: '',
  escritorio_criteria: '',
  nome_investidor: '',
  cpf_investidor: '',
  email: '',
  endereco_cep: '',
  endereco_rua: '',
  endereco_bairro: '',
  endereco_cidade: '',
  endereco_estado: '',
  data_integralizacao: null,
  conta_bancaria: '',
  rentabilidade: '',
  rentabilidade_um: '',
  rentabilidade_dois: '',
  rentabilidade_tres: '',
  valor_aplicado: '',
  valor_aplicado_um: '',
  valor_aplicado_dois: '',
  valor_aplicado_tres: '',
  telefone_investidor: '',
  tag: null,
  operador_parceiro: '',
  banco: '',
  data_liquidacao: '',
  taxa_parceiro: '',
  taxa_final: '',
  moeda: '',
  montante_operacao: '',
  desmembramento: 'False',
  qtd_cards: null,
  observacoes: '',
  cpf: '',
  valor_liquido: '',
  parcela_confortavel: '',
  data_nascimento: '',
  finalidade: '',
  cnpj: '',
  quantidade_vidas: '',
  idades: '',
  valor_atual: '',
  operadora: '',
  possui_plano: '',
  profissao: '',
  renda: '',
  patrimonio_investido: '',
  patrimonio_fora_brasil: '',
  patrimonio_imobilizado: '',
  estado: '',
  regime_casamento: '',
  nome_conjuge: '',
  data_nascimento_conjuge: '',
  faturamento_medio_ultimos_meses: '',
  anexo_ultima_atualizacao_contrato_social: '',
  anexo_balanco_dre: '',
  anexo_faturamento_do_ano_anterior: '',
  nome_contato_empresa: '',
  observacao: '',
  nome_completo: '',
}

const model = ref({ ...modelDefault })
const options = ref({
  codigo_assessor: [],
  tipo_de_produto: [
    { label: 'Pessoa Física', value: 'PF' },
    { label: 'Pessoa Jurídica', value: 'PJ' },
  ],
  produto: [],
  tipo_pessoa: data.tipo_pessoa,
})
const file = ref(null)

watch(
  () => [model.value.tipo_de_produto],
  () => {
    if (model.value.tipo_de_produto !== '') {
      options.value.produto =
        model.value.tipo_de_produto === 'PF'
          ? data.produtos_pf
          : data.produtos_pj
    } else {
      options.value.produto = []
    }
    model.value.produto = ''
  },
  { deep: true }
)

const setOptionsAssessores = (assessores) => {
  options.value.codigo_assessor = assessores?.map((i) => ({
    value: i.codigo_assessor,
    label: `${i.nome} (${i.codigo_assessor ?? '-'})`,
  }))
}

watch(
  () => state.value.open,
  async (v) => {
    !v ? (model.value = { ...modelDefault }) : setValuesInFields()
    const assessores = await getAssessores()
    setOptionsAssessores(assessores)
  },
  { deep: true }
)

const emit = defineEmits(['create:indicacao'])
const loading = ref({ modal: false })
const formRef = ref(null)
const { cols, getCols } = useKanban(urls)

async function postIndicacao() {
  const coluna_kanban = unref(cols)[0].id
  const isPf = model.value.tipo_pessoa === 'Pessoa Física'
  const cambiopf = isPf ? model.value.cliente : null
  const cambiopj = !isPf ? model.value.cliente : null
  const formData = new FormData()

  for (const [key, value] of Object.entries(model.value)) {
    formData.append(key, value)
    if (key === 'tag' && value === null) {
      formData.delete('tag')
    }
    
  }
  if(file.value)
    formData.append('anexo', file.value)

  formData.append('coluna_kanban', coluna_kanban)
  formData.append('cliente_cambio_pf', cambiopf)
  formData.append('cliente_cambio_pj', cambiopj)
  formData.append(
    'montante_operacao',
    String(model.value.montante_operacao).replace(',', '.')
  )
  formData.append(
    'taxa_parceiro',
    String(model.value.taxa_parceiro).replace(',', '.')
  )
  formData.append(
    'taxa_final',
    String(model.value.taxa_final).replace(',', '.')
  )

  const indicacao = await postIndicacaoMesaSeguro(formData)

  return indicacao
}

async function onSubmit() {
  const valid = await formRef.value.validate()

  if (!valid) {
    formRef.value.$el.scrollIntoView()
    NotifyError('Por favor, preencha os campos obrigatórios')
    return
  }
  try {
    loading.value.modal = true

    const indicacao = await postIndicacao()

    emit('create:indicacao', indicacao)

    model.value = { ...modelDefault }
    NotifySucess('Indicacão realizada com sucesso')
    Emitter.emit('update:kanban')
    close()
  } catch (e) {
    console.log(e)
    NotifyError('Ocorreu um erro durante a criação')
  } finally {
    loading.value.modal = false
  }
}

function setValuesInFields() {
  model.value.produto = urlFilters.value.produto
  if (urlFilters.value.tipo) {
    model.value.tipo_de_produto = urlFilters.value.tipo
  }
}

onMounted(async () => {
  await getCols('mesa_seguro', false)

  setValuesInFields()
})
</script>

<style lang="scss" scoped></style>
