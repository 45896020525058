<template>
  <section class="flex flex-col gap-40 mx-auto teste">
    <div class="grid grid-cols-[1.4fr_1.2fr] md:grid-cols-1 gap-24">
      <CardImageLeft
        :image="newUrlImg('images/consorcio.webp')"
        icon-name="svguse:#icon_detail_consorcio"
      />

      <div class="flex flex-col gap-24">
        <h2 class="text-title-1 text-neutral-100">Consórcio</h2>
        <p class="text-pararaphy-1 text-neutral-70">
          <span class="font-semibold text-neutral-100"> Consórcio</span> é uma
          solução inteligente para planejar e conquistar bens de alto valor,
          como imóveis, veículos e outros. Com ele, você participa de um grupo
          de pessoas que contribuem mensalmente para a formação de um fundo
          comum, possibilitando a aquisição do bem desejado sem pagar juros.
          Flexível e acessível, o consórcio permite que você invista no futuro,
          com segurança e planejamento financeiro.
        </p>

        <div class="flex gap-16 mt-24 md:justify-center">
          <OButton
            primary
            size="md"
            @click="emits('click:openModalCreateIndicacao')"
            >Faça sua indicação à mesa</OButton
          >
          <OButton
            secondary
            size="md"
            icon="svguse:#icon_download"
            @click="emits('click:download', data.documento_consorcio)"
            >Lâminas de Materiais</OButton
          >
        </div>
      </div>
    </div>
    <div>
      <swiper :space-between="20" slides-per-view="auto">
        <swiper-slide
          v-for="(modalidade, index) in modalidades"
          :key="index"
          class="max-w-[24.5rem] w-[24.5rem] min-w-[24.5rem] p-24"
        >
          <div class="flex flex-col gap-4">
            <h2 class="text-headline-1 text-neutral-100">
              {{ modalidade.title }}
            </h2>
            <span class="text-pararaphy-1 text-neutral-70">
              {{ modalidade.texto }}
            </span>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import CardImageLeft from './CardImageLeft.vue'
import GLOBAL from '../../../utils/GLOBAL'
import OButton from '../Button/OButton.vue'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { inject, onMounted } from 'vue'

const { newUrlImg } = GLOBAL

const emits = defineEmits(['click:openModalCreateIndicacao', 'click:download'])
const { data } = inject('context')
const modalidades = [
  {
    title: 'Consórcio de Imóveis',
    texto:
      'Planeje a compra da casa dos seus sonhos ou invista em imóveis com parcelas acessíveis, sem juros, e com a possibilidade de utilizar o FGTS',
  },

  {
    title: 'Consórcio de Veículos',
    texto:
      'Adquira seu carro, moto ou veículo pesado com parcelas que cabem no seu bolso, sem pagar juros, e com prazos flexíveis',
  },

  {
    title: 'Consórcio de Barcos',
    texto:
      'Realize o sonho de adquirir seu barco com parcelas acessíveis e sem juros, planejando com segurança e flexibilidade',
  },

  {
    title: 'Consórcio de Aeronaves',
    texto:
      'Invista em sua própria aeronave, com condições planejadas e sem juros, facilitando a compra de forma acessível e organizada',
  },

  {
    title: 'Consórcio de Motos',
    texto:
      'Conquiste a sua moto nova com parcelas leves e flexíveis, sem juros, com prazos que se adaptam à sua realidade',
  },

  {
    title: 'Consórcio de Serviços',
    texto:
      'Financie reformas, viagens, tratamentos médicos ou qualquer serviço de alto custo de forma planejada e sem juros',
  },
]
</script>

<style lang="scss" scoped></style>
